<template>
  <v-card>
    <!-- <v-card-title class="justify-center">
      <span class="text-h5"><strong> Tissue List</strong></span>
    </v-card-title> -->
    <v-card-title class="justify-space-between mb-0 py-7">
            <span class="text-h5"><strong>Tissue List</strong></span>
        </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.tissue_id" label="Tissue ID" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.tissue_category" label="Tissue Category" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          label="Storage Medium"
          v-model="filter.storage_medium"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field label="Outcome of Tissue" v-model="filter.tissue_outcome" outlined dense hide-details></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-btn color="primary" class="mr-4"  @click="TissueSearch()"> Search</v-btn>
        <v-btn color="primary"  @click="Tissueget()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="2">
        
      </v-col> -->
    </v-row>

    <v-data-table
      :headers="headers"
      :items="TissueItem"
      :items-per-page="10"
      fixed-header
      height="400"
      class="table-rounded"
     
    >
      <template #[`item.excision_date_time`]="{ item }">
        <pre>{{moment.utc(item.excision_date_time).format("DD-MM-YYYY HH:mm")}}</pre>
      </template>
      <template #[`item.operate`]="{}">
        <!-- <v-btn color="primary" class="mr-3" @click="aa()"> Print </v-btn> -->
        <!-- <v-btn color="error" text @click="vv()"> Delete </v-btn> -->
      </template>
      <!-- <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="pt-3 col-12">
          <v-col  sm="6" md="6">
            <p><strong> General information: </strong></p>
          <p>MR No: {{ item.medical_registration_no }}</p>
          <p>ID(Aadhar or PAN No.): {{ item.aadhar_pan_no }}</p>
          <p>Address: {{ item.address }}</p>
          </v-col>
          <v-col  sm="6" md="6">
            <p></p>
          <p >Age: {{ item.age }}</p>
          <p >Gender: {{ item.gender }}</p>
          <p >Email:  {{ item.email }}</p>
        </v-col>
        </v-row>
      </td>
    </template> -->
    </v-data-table>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import TissueService from '@/service/Tissue.service'
import { api } from '@/config/config'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      expanded:[],
      singleExpand: true,
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      TissueItem: [],
      filter: {
        tissue_id: '',
        storage_medium: '',
        tissue_category: '',
        tissue_outcome: '',
      },

      headers: [
        { text: 'Tissue ID', value: 'tissue_id', width: '150px' },
        { text: 'Excision Date-Time', value: 'excision_date_time', width: '150px' },
        { text: 'Approved Usages', value: 'approved_usages', width: '150px' },
        { text: 'Tissue Category', value: 'tissue_category', width: '150px' },
        { text: 'Storage Medium', value: 'storage_medium', width: '150px' },
        { text: 'Outcome of Tissue', value: 'tissue_outcome', width: '150px' },
        //  { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.Tissueget()
      },
      deep: true,
    },
  },

  async mounted() {
    this.Tissueget()
  },

  methods: {
    async Tissueget() {
      this.listLoading = true
      const service = new TissueService()
      let response = await service.TissueList()
      if (response) {
        this.TissueItem = response
        // console.log("Hello Bill2",response)
        this.totallist = this.TissueItem.length
        ;(this.filter.tissue_id = ''),
          (this.filter.storage_medium = ''),
          (this.filter.tissue_category = ''),
          (this.filter.tissue_outcome = '')
      } else {
        this.TissueItem = []
        this.totallist = 0
      }
    },

    async TissueSearch() {
      this.listLoading = true
      // const data = {
      //   tissue_id: this.filter.tissue_id,
      //   storage_medium: this.filter.storage_medium,
      //   tissue_category: this.filter.tissue_category,
      //   tissue_outcome: this.filter.tissue_outcome,
      // }

      if (this.filter.tissue_id == '' && this.filter.storage_medium == '' && this.filter.tissue_category == '' && this.filter.tissue_outcome == '') {
        this.snackbarText = 'Kindly Enter The Keyword',
          this.snackbar = true
        return;
      }
      var finalData = []
      this.TissueItem.filter((data) => {
        if(this.filter.tissue_id != "" && data.tissue_id == this.filter.tissue_id){
          finalData.push(data)
        }else if(this.filter.tissue_category != "" && data.tissue_category == this.filter.tissue_category){
          finalData.push(data)
        }else if(this.filter.storage_medium != "" && data.storage_medium == this.filter.storage_medium){
          finalData.push(data)
        }else if(this.filter.tissue_outcome != "" && data.tissue_outcome == this.filter.tissue_outcome){
          finalData.push(data)
        }
      })
      this.TissueItem = finalData
      this.totallist = finalData.length
      // try {
      //   const response = await api.post(`tissueList/tissue_list_searching`, data, {
      //     headers: {
      //       'Content-type': 'application/json',
      //       Authorization: `Bearer ${token}`,
      //     },
      //   })
      //   if (response) {
      //     this.TissueItem = response.data
      //     this.totallist = this.TissueItem.length
      //   } else {
      //     this.TissueItem = []
      //     this.totallist = 0
      //   }
      //   this.listLoading = false
      // } catch (e) {
      //   console.log(e)
      // }
    },
  },
}
</script>
